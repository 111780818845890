import { APIFilter } from '@/utils/api'

export default {
  async selectSyncUpload (Vue, idsyncUpload) {
    const apiFilter = new APIFilter()
    apiFilter.addExact('idsync_upload', idsyncUpload)
    const resp = await Vue.$api.call('syncUpload.select', { filter: apiFilter })
    return [resp.data.result.dataset[0], resp.data.result.metadata]
  },
  async selectSync (Vue, idsyncUpload) {
    const apiFilter = new APIFilter()
    apiFilter.addExact('idsync_upload', idsyncUpload)
    const resp = await Vue.$api.call('sync.select', { filter: apiFilter })
    return [resp.data.result.dataset, resp.data.result.metadata]
  }
}
