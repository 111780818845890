<template>
  <b10-base>
    <b10-toolbar
      :title="title"
      :options="toolbarOptions"
      @click-option="clickToolbarOption"
    />
    <b10-page-content>
      <b10-view-summary
        :item="formattedItem"
        :showing.sync="showingDialogs.moreInfo"
        :loading-data="loadingData"
        :show-alerts="!routeFromBrowserHistory"
      />
    </b10-page-content>
  </b10-base>
</template>

<script>
import _ from '@/utils/lodash'
import { viewPageMixin } from '@/mixins/viewPageMixin'
import Data from './SyncUploadViewData'

export default {
  mixins: [viewPageMixin],
  beforeRouteLeave (to, from, next) {
    return this.beforeRouteLeaveBase(to, from, next)
  },
  data () {
    return {
      moreInfoRows: [
        { name: 'fecha', filter: this.$options.filters.shortDateTime },
        'cant_cambios',
        'con_errores',
      ],
      showingDialogs: {
        moreInfo: false,
      },
      toolbarOptions: {
        importarCambios: {
          title: 'Importar cambios',
          visible: true,
          icon: 'import',
        },
      },
    }
  },
  computed: {
    formattedItem () {
      if (!_.isEmpty(this.item.dataset)) {
        const item = _.cloneDeep(this.item.dataset)
        item.id = item.idsync_upload
        item.title = this.$options.filters.shortDateTime(item.fecha)
        item.subtitle = `${item.cant_cambios} ${this.$options.filters.pluralize('cambio', item.cant_cambios, 's')}`
        if (item.con_errores) {
          item.badge = 'Con errores'
          item.badgeColor = 'error'
        } else {
          item.badge = 'Correcta'
          item.badgeColor = 'success'
        }
        item.moreInfo = this.buildMoreInfo(this.item.metadata)
        return item
      } else {
        return {}
      }
    },
  },
  async created () {
    await this.initStore()
    await this.loadPage()
  },
  methods: {
    async loadPage () {
      await this.loadItem()
      this.title = `Sincronización Nº${this.item.dataset.idsync_upload}`
    },
    async loadItem () {
      this.loadingData = true
      try {
        const [dataset, metadata] = await Data.selectSyncUpload(this, this.routeParams.idsync_upload)
        if (dataset) {
          this.$set(this.item, 'dataset', dataset)
          this.$set(this.item, 'metadata', metadata)
        } else {
          this.itemNotFound()
        }
      } finally {
        this.loadingData = false
      }
    },
    async clickToolbarOption (option) {
      if (option === this.toolbarOptions.importarCambios) {
        const res = await this.$alert.showConfirm(
          `¿Deseas importar todos los cambios de la sincronización Nº${this.routeParams.idsync_upload}?`
        )
        if (res) {
          this.$loading.showManual('Importando cambios...')
          try {
            const [cambiosDataset] = await Data.selectSync(this, this.routeParams.idsync_upload)
            for (const cambio of cambiosDataset) {
              await this.$offline.sync.insert({
                method: cambio.funcion,
                params: JSON.parse(cambio.params)
              })
            }
            this.$alert.showSnackbarSuccess(
              `Se han importado ${cambiosDataset.length} ${this.$options.filters.pluralize('cambio', cambiosDataset.length, 's')}`
            )
          } finally {
            this.$loading.hide()
          }
        }
      }
    },
  }
}
</script>
